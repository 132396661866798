import {SagaIterator} from 'redux-saga'
import {takeLatest, all, call} from 'redux-saga/effects'
import {FETCH_ASSOCIATES_START} from './associates.constants'
import {fetchAssociatesStartSaga} from './associates.sagas'

export function* onFetchAssociatesStart(): SagaIterator<void> {
  yield takeLatest(FETCH_ASSOCIATES_START, fetchAssociatesStartSaga)
}

export function* associatesSagas(): SagaIterator<void> {
  yield all([call(onFetchAssociatesStart)])
}
