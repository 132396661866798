import ActionBar from 'components/common/action-bar.component'
import BaseModal from 'components/common/base-modal.component'
import {
  StyledNavLinkRow,
  StyledTableRow,
} from 'components/common/styles/row.styles'
import AdminLayout from 'components/layouts/admin.component'
import React, {FC, useState, useMemo} from 'react'
import {Col, Nav, Row} from 'reactstrap'
import TableFilters from 'components/common/tables/table-filter.component'
import SubNavLinks from 'components/common/sub-nav-links.component'
import {fetchReferredUsersStart} from '_redux/referrals/referrals.actions'
import {usePagination} from 'components/common/hooks/use-pagination.hook'
import {useSelector} from 'react-redux'
import {
  selectReferredUsersRequesting,
  makeGetSortedReferredUsers,
} from '_redux/referrals/referrals.selectors'
import {ReferralStatus} from '_redux/referrals/referrals.types'
import LoadingOrEmptyModel from 'components/common/loading-or-empty-model.component'
import {TableBodyData, TSortBy} from 'components/common/types/table-body.types'
import {AppState} from '_redux/store.types'
import {useTabs} from 'components/common/hooks/use-tabs.hook'
import {TableWithDropdown} from 'components/common/tables'
import {transformReferredUsersForTable} from './utils'
import {bondsRowsForReferralsTable} from './table-bonds-rows.component'

const referralsValue = {
  active: 'Active',
  pending: 'Pending',
}

const DashboardReferrals: FC = () => {
  const {tab, onTabClick} = useTabs('all')
  const [openAddModal, setOpenAddModal] = useState(false)
  const [sortOrder, setSortOrder] = useState<TSortBy>('newest')
  const toggleOpenAddModal = () => {
    setOpenAddModal(prev => !prev)
  }
  const [
    currentReferredUsersPage,
    loadMoreReferredUsers,
    resetCurrentPage,
    setExtraQueryParams,
  ] = usePagination(fetchReferredUsersStart)
  const referredUsersRequesting = useSelector(selectReferredUsersRequesting)
  const selectReferrals = useMemo(makeGetSortedReferredUsers, [])
  const referrals = useSelector((state: AppState) =>
    selectReferrals(state, sortOrder),
  )
  const bondsRowsForTable = bondsRowsForReferralsTable(referrals)

  const transformedReferredUsers: TableBodyData[] = transformReferredUsersForTable(
    referrals,
    referralsValue[tab],
  )
  const handleTabClick = (_tab: string) => {
    const lowercaseTab = _tab.toLowerCase()
    if (!/all/.test(lowercaseTab)) {
      setExtraQueryParams({query: ReferralStatus[lowercaseTab]})
    }

    if (lowercaseTab !== tab) {
      resetCurrentPage()
    }
    onTabClick(lowercaseTab)
  }

  const handleSort = (sortBy: TSortBy) => {
    setSortOrder(sortBy)
  }

  return (
    <AdminLayout pageTitle="Referrals">
      <Row>
        <ActionBar onAddClick={toggleOpenAddModal} headerText="Referrals" />
      </Row>
      <StyledNavLinkRow className="border-bottom mb-3">
        <Nav>
          <SubNavLinks
            tabs={['All', 'Active', 'Pending']}
            currentTab={tab}
            toggleCurrentTab={handleTabClick}
          />
        </Nav>
      </StyledNavLinkRow>

      <BaseModal
        isOpen={openAddModal}
        toggle={toggleOpenAddModal}
        title="Create Account"
      >
        {/* <AddBondFormOverview toggleModal={closeAddModal} /> */}
      </BaseModal>
      {transformedReferredUsers.length ? (
        <StyledTableRow className="pb-3">
          <Col sm="12" className="border rounded">
            <TableFilters
              handleSort={handleSort}
              loaded={transformedReferredUsers.length}
              total={transformedReferredUsers.length}
            />
            <div>
              <TableWithDropdown
                headers={[
                  '',
                  'ID',
                  'Name',
                  'Membership',
                  'Location',
                  'Status',
                  'Join Date',
                  'Referred by',
                  'Referrals',
                  'Amount',
                  '',
                ]}
                hasActiveColor
                loading={referredUsersRequesting}
                page={currentReferredUsersPage}
                body={transformedReferredUsers}
                expandedRowData={bondsRowsForTable}
                hasMore={true}
                loadMore={loadMoreReferredUsers}
              />
            </div>
          </Col>
        </StyledTableRow>
      ) : (
        <LoadingOrEmptyModel loading={referredUsersRequesting} />
      )}
    </AdminLayout>
  )
}

export default DashboardReferrals
