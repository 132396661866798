import {combineReducers} from 'redux'
import {DashboardModelMeta} from 'components/common/types/dashboard-models.types'
import {
  FETCH_WITHDRAWALS_START,
  FETCH_WITHDRAWALS_SUCCESS,
  FETCH_WITHDRAWALS_FAILURE,
  SET_WITHDRAWALS_META,
} from './withdrawals.constants'
import {
  WithdrawalsDictionary,
  WithdrawalsIdList,
  WithdrawalsRequestingStateActions,
} from './withdrawals.types'
import {
  SetWithdrawalsMetaAction,
  FetchWithdrawalsSuccessAction,
} from './withdrawals-actions.types'

function withdrawalsById(
  state: WithdrawalsDictionary = {},
  {type, payload}: FetchWithdrawalsSuccessAction,
): WithdrawalsDictionary {
  switch (type) {
    case FETCH_WITHDRAWALS_SUCCESS: {
      if (typeof payload !== 'number') {
        return {...state, ...payload.entities.withdrawals}
      }
      return state
    }
    default:
      return state
  }
}

function allWithdrawalsIds(
  state: WithdrawalsIdList = [],
  {type, payload}: FetchWithdrawalsSuccessAction,
): WithdrawalsIdList {
  switch (type) {
    case FETCH_WITHDRAWALS_SUCCESS: {
      if (typeof payload !== 'number') {
        const stateCopy = [...state, ...payload.result]
        return Array.from(new Set(stateCopy))
      }
      return state
    }
    default:
      return state
  }
}

function withdrawalsMeta(
  state: DashboardModelMeta = {pages: 0},
  {type, payload}: SetWithdrawalsMetaAction,
): DashboardModelMeta {
  switch (type) {
    case SET_WITHDRAWALS_META:
      return payload
    default:
      return state
  }
}

function withdrawalsRequesting(
  state = true,
  {type}: WithdrawalsRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_WITHDRAWALS_START:
      return true
    case FETCH_WITHDRAWALS_SUCCESS:
    case FETCH_WITHDRAWALS_FAILURE:
      return false
    default:
      return state
  }
}

const withdrawalsReducer = combineReducers({
  byId: withdrawalsById,
  allIds: allWithdrawalsIds,
  meta: withdrawalsMeta,
  requesting: withdrawalsRequesting,
})

export default withdrawalsReducer
