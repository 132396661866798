import {
  DashboardModelMeta,
  DashboardModelQuery,
} from 'components/common/types/dashboard-models.types'
import {
  ADD_AD_START,
  FETCH_ADS_FAILURE,
  FETCH_ADS_START,
  FETCH_ADS_SUCCESS,
  FETCH_AD_FAILURE,
  FETCH_AD_START,
  FETCH_AD_SUCCESS,
  SET_ADS_META,
} from './ads.constants'
import {Ad, AddAdCredentials, AdsActionTypes, NormalizedAds} from './ads.types'

export function fetchAdsStart(data: DashboardModelQuery): AdsActionTypes {
  return {
    type: FETCH_ADS_START,
    payload: data,
  }
}

export function fetchAdsSuccess(data: NormalizedAds): AdsActionTypes {
  return {
    type: FETCH_ADS_SUCCESS,
    payload: data,
  }
}

export function fetchAdsFailure(): AdsActionTypes {
  return {
    type: FETCH_ADS_FAILURE,
  }
}

export function setAdsMeta(meta: DashboardModelMeta): AdsActionTypes {
  return {
    type: SET_ADS_META,
    payload: meta,
  }
}

export function fetchAdStart(id: string): AdsActionTypes {
  return {
    type: FETCH_AD_START,
    payload: id,
  }
}

export function fetchAdSuccess(data: Ad): AdsActionTypes {
  return {
    type: FETCH_AD_SUCCESS,
    payload: data,
  }
}

export function fetchAdFailure(): AdsActionTypes {
  return {
    type: FETCH_AD_FAILURE,
  }
}

export function addAdStart(adData: AddAdCredentials): AdsActionTypes {
  return {
    type: ADD_AD_START,
    payload: adData,
  }
}
