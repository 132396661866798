import {SagaIterator} from 'redux-saga'
import {takeLatest, all, call} from 'redux-saga/effects'
import {FETCH_WITHDRAWALS_START} from './withdrawals.constants'
import {FetchWithdrawalsStartSaga} from './withdrawals.sagas'

export function* onFetchWithdrawalsStart(): SagaIterator<void> {
  yield takeLatest(FETCH_WITHDRAWALS_START, FetchWithdrawalsStartSaga)
}

export function* withdrawalsSagas(): SagaIterator<void> {
  yield all([call(onFetchWithdrawalsStart)])
}
