import {put, call} from 'redux-saga/effects'
import {SagaIterator} from 'redux-saga'
import * as schema from '_redux/schema'
import log from 'loglevel'
import AssociatesService from 'services/api/associates/service'
import {normalize} from 'normalizr'
import {FetchAssociatesStartAction} from './associates-actions.types'
import {
  fetchAssociatesFailure,
  fetchAssociatesSuccess,
  setAssociatesMeta,
} from './associates.actions'

export function* fetchAssociatesStartSaga({
  payload: query,
}: FetchAssociatesStartAction): SagaIterator<void> {
  const associatesService = new AssociatesService()
  try {
    const {
      data: {data: associates, pages},
    } = yield call([associatesService, 'fetchAssociates'], query)
    const normalizedAssociates = yield call(
      normalize,
      associates,
      schema.arrayOfAssociates,
    )
    yield put(fetchAssociatesSuccess(normalizedAssociates))
    yield put(setAssociatesMeta({pages}))
  } catch (error) {
    log.warn(error)
    yield put(fetchAssociatesFailure())
  }
}
