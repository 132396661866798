import {schema} from 'normalizr'

export const bond = new schema.Entity('bonds')
export const arrayOfBonds = new schema.Array(bond)

export const ad = new schema.Entity('ads')
export const arrayOfAds = new schema.Array(ad)

export const referral = new schema.Entity('referrals')
export const arrayOfReferrals = new schema.Array(referral)

export const reward = new schema.Entity('rewards')
export const arrayOfRewards = new schema.Array(reward)

export const returns = new schema.Entity('returns')
export const arrayOfReturns = new schema.Array(returns)

export const withdrawals = new schema.Entity('withdrawals')
export const arrayOfWithdrawals = new schema.Array(withdrawals)

export const referredUser = new schema.Entity('referredUsers')
export const arrayOfReferredUsers = new schema.Array(referredUser)

export const member = new schema.Entity('members')
export const arrayOfMembers = new schema.Array(member)

export const associate = new schema.Entity('associates')
export const arrayOfAssociates = new schema.Array(associate)
