import {
  DashboardModelMeta,
  DashboardModelQuery,
} from 'components/common/types/dashboard-models.types'
import {
  FETCH_REFERRED_USERS_START,
  FETCH_REFERRED_USERS_SUCCESS,
  FETCH_REFERRED_USERS_FAILURE,
  FETCH_REFERRALS_START,
  FETCH_REFERRALS_SUCCESS,
  FETCH_REFERRALS_FAILURE,
  SET_REFERRALS_META,
  SET_REFERRED_USERS_META,
  FETCH_REFERRAL_CHART_DATA_FAILURE,
  FETCH_REFERRAL_CHART_DATA_SUCCESS,
  FETCH_REFERRAL_CHART_DATA_START,
} from './referrals.constants'
import {
  NormalizedReferrals,
  ReferralsActionTypes,
  NormalizedReferredUsers,
  ReferralData,
} from './referrals.types'

export function fetchReferralsStart(
  data: DashboardModelQuery,
): ReferralsActionTypes {
  return {
    type: FETCH_REFERRALS_START,
    payload: data,
  }
}

export function fetchReferralsSuccess(
  data: NormalizedReferrals,
): ReferralsActionTypes {
  return {
    type: FETCH_REFERRALS_SUCCESS,
    payload: data,
  }
}

export function fetchReferralsFailure(): ReferralsActionTypes {
  return {
    type: FETCH_REFERRALS_FAILURE,
  }
}

export function setReferralsMeta(
  meta: DashboardModelMeta,
): ReferralsActionTypes {
  return {
    type: SET_REFERRALS_META,
    payload: meta,
  }
}

export function fetchReferredUsersStart(
  data: DashboardModelQuery,
): ReferralsActionTypes {
  return {
    type: FETCH_REFERRED_USERS_START,
    payload: data,
  }
}

export function fetchReferredUsersSuccess(
  data: NormalizedReferredUsers,
): ReferralsActionTypes {
  return {
    type: FETCH_REFERRED_USERS_SUCCESS,
    payload: data,
  }
}

export function fetchReferredUsersFailure(): ReferralsActionTypes {
  return {
    type: FETCH_REFERRED_USERS_FAILURE,
  }
}

export function setReferredUsersMeta(
  meta: DashboardModelMeta,
): ReferralsActionTypes {
  return {
    type: SET_REFERRED_USERS_META,
    payload: meta,
  }
}

export function fetchReferralChartDataStart(): ReferralsActionTypes {
  return {
    type: FETCH_REFERRAL_CHART_DATA_START,
  }
}
export function fetchReferralChartDataSuccess(
  data: ReferralData[],
): ReferralsActionTypes {
  return {
    type: FETCH_REFERRAL_CHART_DATA_SUCCESS,
    payload: data,
  }
}
export function fetchReferralChartDataFailure(): ReferralsActionTypes {
  return {
    type: FETCH_REFERRAL_CHART_DATA_FAILURE,
  }
}
