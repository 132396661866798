import {combineReducers} from 'redux'
import {DashboardModelMeta} from 'components/common/types/dashboard-models.types'
import {
  FETCH_REFERRALS_START,
  FETCH_REFERRALS_SUCCESS,
  FETCH_REFERRALS_FAILURE,
  SET_REFERRALS_META,
  FETCH_REFERRED_USERS_SUCCESS,
  SET_REFERRED_USERS_META,
  FETCH_REFERRED_USERS_START,
  FETCH_REFERRED_USERS_FAILURE,
  FETCH_REFERRAL_CHART_DATA_SUCCESS,
  FETCH_REFERRAL_CHART_DATA_START,
  FETCH_REFERRAL_CHART_DATA_FAILURE,
} from './referrals.constants'
import {
  ReferralsDictionary,
  ReferralsIdList,
  ReferralsRequestingStateActions,
  ReferredUsersDictionary,
  ReferredUsersIdList,
  ReferredUsersRequestingStateActions,
  ReferralChartDataRequestingStateActions,
  ReferralData,
} from './referrals.types'
import {
  SetReferralsMetaAction,
  FetchReferralsSuccessAction,
  FetchReferredUsersSuccessAction,
  SetReferredUsersMetaAction,
  FetchReferralChartDataSuccessAction,
} from './referrals-actions.types'

function referralsById(
  state: ReferralsDictionary = {},
  {type, payload}: FetchReferralsSuccessAction,
): ReferralsDictionary {
  switch (type) {
    case FETCH_REFERRALS_SUCCESS: {
      if (typeof payload !== 'number') {
        return {...state, ...payload.entities.referrals}
      }
      return state
    }
    default:
      return state
  }
}

function allReferralsIds(
  state: ReferralsIdList = [],
  {type, payload}: FetchReferralsSuccessAction,
): ReferralsIdList {
  switch (type) {
    case FETCH_REFERRALS_SUCCESS: {
      if (typeof payload !== 'number') {
        const stateCopy = [...state, ...payload.result]
        return Array.from(new Set(stateCopy))
      }
      return state
    }
    default:
      return state
  }
}

function referralsMeta(
  state: DashboardModelMeta = {pages: 0},
  {type, payload}: SetReferralsMetaAction,
): DashboardModelMeta {
  switch (type) {
    case SET_REFERRALS_META:
      return payload
    default:
      return state
  }
}

function referralsRequesting(
  state = true,
  {type}: ReferralsRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_REFERRALS_START:
      return true
    case FETCH_REFERRALS_SUCCESS:
    case FETCH_REFERRALS_FAILURE:
      return false
    default:
      return state
  }
}

function referredUsersById(
  state: ReferredUsersDictionary = {},
  {type, payload}: FetchReferredUsersSuccessAction,
): ReferredUsersDictionary {
  switch (type) {
    case FETCH_REFERRED_USERS_SUCCESS: {
      if (typeof payload !== 'number') {
        return {...state, ...payload.entities.referredUsers}
      }
      return state
    }
    default:
      return state
  }
}

function allReferredUsersIds(
  state: ReferredUsersIdList = [],
  {type, payload}: FetchReferredUsersSuccessAction,
): ReferredUsersIdList {
  switch (type) {
    case FETCH_REFERRED_USERS_SUCCESS: {
      if (typeof payload !== 'number') {
        const stateCopy = [...state, ...payload.result]
        return Array.from(new Set(stateCopy))
      }
      return state
    }
    default:
      return state
  }
}

function referredUsersMeta(
  state: DashboardModelMeta = {pages: 0},
  {type, payload}: SetReferredUsersMetaAction,
): DashboardModelMeta {
  switch (type) {
    case SET_REFERRED_USERS_META:
      return payload
    default:
      return state
  }
}

function referredUsersRequesting(
  state = true,
  {type}: ReferredUsersRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_REFERRED_USERS_START:
      return true
    case FETCH_REFERRED_USERS_SUCCESS:
    case FETCH_REFERRED_USERS_FAILURE:
      return false
    default:
      return state
  }
}

const referralChartData = (
  state: ReferralData[] = [],
  {type, payload}: FetchReferralChartDataSuccessAction,
): ReferralData[] => {
  switch (type) {
    case FETCH_REFERRAL_CHART_DATA_SUCCESS:
      return payload
    default:
      return state
  }
}

export function referralChartDataRequesting(
  state = true,
  {type}: ReferralChartDataRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_REFERRAL_CHART_DATA_START:
      return true
    case FETCH_REFERRAL_CHART_DATA_SUCCESS:
    case FETCH_REFERRAL_CHART_DATA_FAILURE:
      return false
    default:
      return state
  }
}

const latestReferrals = combineReducers({
  byId: referralsById,
  allIds: allReferralsIds,
  meta: referralsMeta,
  requesting: referralsRequesting,
})

const referredUsers = combineReducers({
  byId: referredUsersById,
  allIds: allReferredUsersIds,
  meta: referredUsersMeta,
  requesting: referredUsersRequesting,
})

const referralCharts = combineReducers({
  data: referralChartData,
  requesting: referralChartDataRequesting,
})

const referralsReducer = combineReducers({
  latest: latestReferrals,
  users: referredUsers,
  charts: referralCharts,
})

export default referralsReducer
