import {put, call} from 'redux-saga/effects'
import {SagaIterator} from 'redux-saga'
import * as schema from '_redux/schema'
import log from 'loglevel'
import WithdrawalsService from 'services/api/withdrawals/service'
import {normalize} from 'normalizr'
import {FetchWithdrawalsStartAction} from './withdrawals-actions.types'
import {
  fetchWithdrawalsFailure,
  fetchWithdrawalsSuccess,
  setWithdrawalsMeta,
} from './withdrawals.actions'

export function* FetchWithdrawalsStartSaga({
  payload,
}: FetchWithdrawalsStartAction): SagaIterator<void> {
  const withdrawalsService = new WithdrawalsService()
  try {
    const {
      data: {data: withdrawals, pages},
    } = yield call([withdrawalsService, 'fetchWithdrawals'], payload)
    const normalizedWithdrawals = yield call(
      normalize,
      withdrawals,
      schema.arrayOfWithdrawals,
    )
    yield put(fetchWithdrawalsSuccess(normalizedWithdrawals))
    yield put(setWithdrawalsMeta({pages}))
  } catch (error) {
    log.warn(error)
    yield put(fetchWithdrawalsFailure())
  }
}
