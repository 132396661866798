import {combineReducers} from 'redux'
import {DashboardModelMeta} from 'components/common/types/dashboard-models.types'
import {
  FETCH_ASSOCIATES_START,
  FETCH_ASSOCIATES_SUCCESS,
  FETCH_ASSOCIATES_FAILURE,
  SET_ASSOCIATES_META,
} from './associates.constants'
import {
  AssociatesDictionary,
  AssociatesIdList,
  AssociatesRequestingStateActions,
} from './associates.types'
import {
  FetchAssociatesSuccessAction,
  SetAssociatesMetaAction,
} from './associates-actions.types'

export function associatesById(
  state: AssociatesDictionary = {},
  {type, payload}: FetchAssociatesSuccessAction,
): AssociatesDictionary {
  switch (type) {
    case FETCH_ASSOCIATES_SUCCESS: {
      return {...state, ...payload.entities.associates}
    }
    default:
      return state
  }
}

export function allAssociatesIds(
  state: AssociatesIdList = [],
  {type, payload}: FetchAssociatesSuccessAction,
): AssociatesIdList {
  switch (type) {
    case FETCH_ASSOCIATES_SUCCESS: {
      const stateCopy = [...state, ...payload.result]
      return Array.from(new Set(stateCopy))
    }
    default:
      return state
  }
}

export function associatesMeta(
  state: DashboardModelMeta = {pages: 0},
  {type, payload}: SetAssociatesMetaAction,
): DashboardModelMeta {
  switch (type) {
    case SET_ASSOCIATES_META:
      return payload
    default:
      return state
  }
}

export function associatesRequesting(
  state = true,
  {type}: AssociatesRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_ASSOCIATES_START:
      return true
    case FETCH_ASSOCIATES_SUCCESS:
    case FETCH_ASSOCIATES_FAILURE:
      return false
    default:
      return state
  }
}

const AssociatesReducer = combineReducers({
  byId: associatesById,
  allIds: allAssociatesIds,
  meta: associatesMeta,
  requesting: associatesRequesting,
})

export default AssociatesReducer
