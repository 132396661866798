import {formatDate} from 'helpers/utils'
import {createSelector, OutputParametricSelector} from 'reselect'
import {AppState} from '_redux/store.types'
import {TSortBy} from 'components/common/types/table-body.types'
import {ReferralsState, ReferredUser, ReferralData} from './referrals.types'

function getReferrals(state: AppState): ReferralsState {
  return state.referrals
}

function getSortedReferredUsers(
  state: AppState,
  sortOrder: TSortBy,
): ReferredUser[] {
  const users = state.referrals.users.allIds.map(
    id => state.referrals.users.byId[id],
  )
  return users.sort((a, b) => {
    const tOne = Date.parse(formatDate(a.Date_joined).toString())
    const tTwo = Date.parse(formatDate(b.Date_joined).toString())
    return sortOrder === 'newest' ? tTwo - tOne : tOne - tTwo
  })
}

export const makeGetSortedReferredUsers = (): OutputParametricSelector<
  AppState,
  TSortBy,
  ReferredUser[],
  (res: ReferredUser[]) => ReferredUser[]
> =>
  createSelector([getSortedReferredUsers], referredUsers => {
    return referredUsers
  })

export const selectReferralsRequesting = createSelector(
  getReferrals,
  referrals => referrals.latest.requesting,
)

export const selectReferralsMeta = createSelector(
  getReferrals,
  referrals => referrals.latest.meta,
)

export const selectReferrals = createSelector(getReferrals, referrals =>
  referrals.latest.allIds.map(id => referrals.latest.byId[id]),
)

export const selectReferredUsersRequesting = createSelector(
  getReferrals,
  referrals => referrals.users.requesting,
)

export const selectReferredUsersMeta = createSelector(
  getReferrals,
  referrals => referrals.users.meta,
)

export const selectReferralChartData = createSelector(
  getReferrals,
  referrals => {
    const sortedReferrals = referrals.charts.data.sort((a, b) => {
      const tOne = Date.parse(formatDate(a.date).toString())
      const tTwo = Date.parse(formatDate(b.date).toString())
      return tTwo - tOne
    })
    const monthsMap: {[key: string]: ReferralData[]} = {}
    for (const referral of sortedReferrals) {
      const monthKey = referral.date.slice(3)
      if (monthsMap[monthKey]) {
        monthsMap[monthKey].push(referral)
      } else {
        monthsMap[monthKey] = [referral]
      }
    }
    return monthsMap
  },
)

export const selectReferralChartDataRequesting = createSelector(
  getReferrals,
  referrals => referrals.charts.requesting,
)
