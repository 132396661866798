export const FETCH_REFERRALS_START = 'FETCH_REFERRALS_START'
export const FETCH_REFERRALS_SUCCESS = 'FETCH_REFERRALS_SUCCESS'
export const FETCH_REFERRALS_FAILURE = 'FETCH_REFERRALS_FAILURE'
export const SET_REFERRALS_META = 'SET_REFERRALS_META'

export const FETCH_REFERRED_USERS_START = 'FETCH_REFERRED_USERS_START'
export const FETCH_REFERRED_USERS_SUCCESS = 'FETCH_REFERRED_USERS_SUCCESS'
export const FETCH_REFERRED_USERS_FAILURE = 'FETCH_REFERRED_USERS_FAILURE'
export const SET_REFERRED_USERS_META = 'SET_REFERRED_USERS_META'

export const FETCH_REFERRAL_CHART_DATA_START = 'FETCH_REFERRAL_CHART_DATA_START'
export const FETCH_REFERRAL_CHART_DATA_SUCCESS =
  'FETCH_REFERRAL_CHART_DATA_SUCCESS'
export const FETCH_REFERRAL_CHART_DATA_FAILURE =
  'FETCH_REFERRAL_CHART_DATA_FAILURE'
