import React, {FC} from 'react'
import format from 'date-fns/format'
import {ReferredUser} from '_redux/referrals/referrals.types'

type Props = {
  bond: {
    [key: number]: {
      bond_name: string
      id: number
      purchase_date: string
      reward: number
      series_name: string
      status: string
      units: number
    }
  }
}

const ReferralsTableBondsRows: FC<Props> = ({bond}) => {
  const bondKeys = Object.keys(bond)
  return (
    <>
      <tr className="bg-light">
        <td />
        <td />
        <td className="font-weight-bold">Bond</td>
        <td className="font-weight-bold">Purchase Date</td>
        <td className="font-weight-bold">Units</td>
        <td className="font-weight-bold">Status</td>
        <td />
        <td />
        <td />
        <td className="font-weight-bold">Reward</td>
        <td />
      </tr>
      {bondKeys.map(key => {
        const {bond_name, purchase_date, units, status, reward} = bond[
          parseInt(key, 10)
        ]
        return (
          <tr className="bg-light" key={key}>
            <td />
            <td />
            <td>
              <u>{bond_name}</u>
            </td>
            <td>
              {format(
                // Date comes in dd.mm.yyyy format instead of dd-mm-yy
                new Date(
                  `${purchase_date.substring(3, 5)}/${purchase_date.substring(
                    0,
                    2,
                  )}/${purchase_date.substring(6)} 00:00:00`,
                ),
                'd MMMM yyyy',
              )}
            </td>
            <td>{units}</td>
            <td>{status}</td>
            <td />
            <td />
            <td />
            <td>£{reward.toLocaleString()}</td>
            <td />
          </tr>
        )
      })}
    </>
  )
}

export default ReferralsTableBondsRows

export const bondsRowsForReferralsTable = (
  referredUsers: ReferredUser[],
): {
  [key: string]: JSX.Element
} => {
  const bondsData: {[key: string]: JSX.Element} = {}
  referredUsers.forEach(user => {
    const data = <ReferralsTableBondsRows bond={user.Bond} />
    bondsData[`${user.id}`] = data
  })
  return bondsData
}
