import {DashboardModelMeta} from 'components/common/types/dashboard-models.types'
import {
  FETCH_BONDS_START,
  FETCH_BONDS_SUCCESS,
  FETCH_BONDS_FAILURE,
  SET_BONDS_META,
  FETCH_BONDS_SUMMARY_START,
  FETCH_BONDS_SUMMARY_SUCCESS,
  FETCH_BONDS_SUMMARY_FAILURE,
  FETCH_BOND_SUCCESS,
  FETCH_BOND_START,
  FETCH_BOND_FAILURE,
  FETCH_INVESTMENT_CHART_DATA_START,
  FETCH_INVESTMENT_CHART_DATA_SUCCESS,
  FETCH_INVESTMENT_CHART_DATA_FAILURE,
} from '../bonds.constants'
import {
  BondsRequestingStateActions,
  BondsStateActions,
  BondsSummaryStateActions,
  BondsSummaryRequestingStateActions,
  InvestmentChartDataRequestingStateActions,
} from '../bonds.types'
import {
  BondsDictionary,
  BondsIdList,
  BondsSummary,
  InvestmentData,
} from './bonds-all.types'
import {
  SetBondsMetaAction,
  FetchInvestmentChartDataSuccessAction,
} from './bonds-all-actions.types'

export function bondsById(
  state: BondsDictionary = {},
  {type, payload}: BondsStateActions,
): BondsDictionary {
  switch (type) {
    case FETCH_BOND_SUCCESS:
    case FETCH_BONDS_SUCCESS: {
      return {...state, ...payload.entities.bonds}
    }
    default:
      return state
  }
}

export function allBondsIds(
  state: BondsIdList = [],
  {type, payload}: BondsStateActions,
): BondsIdList {
  switch (type) {
    case FETCH_BOND_SUCCESS:
    case FETCH_BONDS_SUCCESS: {
      const stateCopy = [...state, ...payload.result]
      return Array.from(new Set(stateCopy))
    }
    default:
      return state
  }
}

export function bondsMeta(
  state: DashboardModelMeta = {pages: 0, total: 0},
  {type, payload}: SetBondsMetaAction,
): DashboardModelMeta {
  switch (type) {
    case SET_BONDS_META:
      return payload
    default:
      return state
  }
}

export function bondsRequesting(
  state = true,
  {type}: BondsRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_BOND_START:
    case FETCH_BONDS_START:
      return true
    case FETCH_BOND_SUCCESS:
    case FETCH_BOND_FAILURE:
    case FETCH_BONDS_SUCCESS:
    case FETCH_BONDS_FAILURE:
      return false
    default:
      return state
  }
}

export function bondsSummary(
  state: BondsSummary | null = null,
  action: BondsSummaryStateActions,
): BondsSummary | null {
  switch (action.type) {
    case FETCH_BONDS_SUMMARY_START:
      return null
    case FETCH_BONDS_SUMMARY_SUCCESS: {
      return action.payload
    }
    default:
      return state
  }
}

export function bondsSummaryRequesting(
  state = true,
  {type}: BondsSummaryRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_BONDS_SUMMARY_START:
      return true
    case FETCH_BONDS_SUMMARY_SUCCESS:
    case FETCH_BONDS_SUMMARY_FAILURE:
      return false
    default:
      return state
  }
}

export const investmentChartData = (
  state: InvestmentData[] = [],
  {type, payload}: FetchInvestmentChartDataSuccessAction,
): InvestmentData[] => {
  switch (type) {
    case FETCH_INVESTMENT_CHART_DATA_SUCCESS:
      return payload
    default:
      return state
  }
}

export function investmentChartDataRequesting(
  state = true,
  {type}: InvestmentChartDataRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_INVESTMENT_CHART_DATA_START:
      return true
    case FETCH_INVESTMENT_CHART_DATA_SUCCESS:
    case FETCH_INVESTMENT_CHART_DATA_FAILURE:
      return false
    default:
      return state
  }
}
