import React, {FC} from 'react'
import {navigate} from 'helpers/utils'
import {TableBodyData} from '../types/table-body.types'

type Props = {
  body: TableBodyData[]
}

const TableBody: FC<Props> = ({body}) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {body.map(
        ({
          header: {
            value: headerValue,
            underlineValue: underlineHeaderValue,
            isImage: headerIsImage,
            isLink: headerIsLink,
            url: headerUrl,
          },
          data,
          disabled,
          classes,
          key,
        }) => {
          const tableHeader = (
            <td
              onClick={
                headerIsLink && headerUrl
                  ? () => {
                      navigate(headerUrl)
                    }
                  : undefined
              }
              className={`${headerIsLink ? 'clickable' : ''} ${
                headerIsLink ? 'clickable' : ''
              }`}
            >
              {headerIsImage ? (
                <img src={headerValue} alt="Avatar" />
              ) : underlineHeaderValue ? (
                <u>{headerValue}</u>
              ) : (
                headerValue
              )}
            </td>
          )
          const tableData = data.map(
            ({value, underlineValue, isImage, isLink, url}, index) => (
              <td
                onClick={
                  isLink && url
                    ? () => {
                        navigate(url)
                      }
                    : undefined
                }
                key={index}
                className={`${isLink ? 'clickable' : ''}`}
              >
                {isImage && typeof value === 'string' ? (
                  <img src={value} alt="Avatar" />
                ) : underlineValue ? (
                  <u>{value}</u>
                ) : (
                  value
                )}
              </td>
            ),
          )
          return (
            <tr
              className={`${disabled ? 'text-muted' : ''} ${
                classes ? classes : ''
              }`}
              key={key}
            >
              {tableHeader}
              {tableData}
            </tr>
          )
        },
      )}
    </>
  )
}

export default TableBody
