import format from 'date-fns/format'
import {Associate} from '_redux/associates/associates.types'
import {titleCaseName} from 'helpers/utils'
import {PlaceholderImage} from 'helpers/placeholder-image'
import {TableBodyData} from 'components/common/types/table-body.types'

export const transformAssociatesForTable = (
  associates: Associate[],
  tab: string | undefined,
): TableBodyData[] => {
  console.log(tab)
  const filteredAssociates = tab
    ? associates.filter(associate => associate.Membership === tab)
    : associates

  return filteredAssociates.map(associate => ({
    key: associate?.id ?? '',
    header: {
      value: PlaceholderImage,
      isImage: true,
    },
    data: [
      {value: associate?.id ?? '-'},
      {
        value: associate?.Name ?? '-',
        underlineValue: true,
        isLink: true,
        url: `/dashboard/members/${associate.user_id}`,
      },
      {value: associate?.Membership ?? '-'},
      {value: associate?.Location ?? '-'},
      {value: titleCaseName(associate?.Status) ?? '-'},
      {
        value: format(
          // Date comes in dd.mm.yyyy format instead of dd-mm-yy
          new Date(
            `${associate?.Date_joined.substring(
              3,
              5,
            )}/${associate?.Date_joined.substring(
              0,
              2,
            )}/${associate?.Date_joined.substring(6)} 00:00:00`,
          ),
          'd MMMM yyyy',
        ),
      },
      {value: associate?.Referrals ?? '-'},
      {value: associate?.Unit ?? '-'},
      {
        value:
          associate?.Amount === 0
            ? associate.Amount
            : `£${associate.Amount.toLocaleString()}`,
      },
    ],
  }))
}
