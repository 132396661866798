import React, {FC, useState, useMemo} from 'react'
import ActionBar from 'components/common/action-bar.component'
import BaseModal from 'components/common/base-modal.component'
import {
  StyledNavLinkRow,
  StyledTableRow,
} from 'components/common/styles/row.styles'
import AdminLayout from 'components/layouts/admin.component'
import {Col, Nav, Row} from 'reactstrap'
import TableFilters from 'components/common/tables/table-filter.component'
import {useSelector} from 'react-redux'
import {fetchAssociatesStart} from '_redux/associates/associates.actions'
import {
  selectAssociatesRequesting,
  selectAssociatesMeta,
  makeGetSortedAssociates,
} from '_redux/associates/associates.selectors'
import LoadingOrEmptyModel from 'components/common/loading-or-empty-model.component'
import {TableBodyData, TSortBy} from 'components/common/types/table-body.types'
import {usePagination} from 'components/common/hooks/use-pagination.hook'
import SubNavLinks from 'components/common/sub-nav-links.component'
import {useTabs} from 'components/common/hooks/use-tabs.hook'
import {BasicTable as AssociateMgmtDetailsTable} from 'components/common/tables'
import {AssociatesMembership} from '_redux/associates/associates.types'
import {AppState} from '_redux/store.types'
import {transformAssociatesForTable} from './utils'

const membershipValues = {
  'bond holder': 'FUND HOLDER',
  green: 'GREEN Associate',
  silver: 'SILVER Associate',
  gold: 'GOLD Associate',
  platinum: 'PLATINUM Associate',
  'platinum plus': 'PLATINUM Plus Associate',
  'brand ambassador': 'AMBASSADOR',
}

const DashboardAssociateMgmt: FC = () => {
  const {tab, onTabClick} = useTabs('all')
  const [openAddModal, setOpenAddModal] = useState(false)
  const [sortOrder, setSortOrder] = useState<TSortBy>('newest')
  const [
    currentAssociatesPage,
    loadMoreAssociates,
    resetCurrentPage,
    setExtraQueryParams,
  ] = usePagination(fetchAssociatesStart)
  const toggleOpenAddModal = () => {
    setOpenAddModal(prev => !prev)
  }

  const handleTabClick = (_tab: string) => {
    const lowercaseTab = _tab.toLowerCase()
    if (!/(all|deleted account)/.test(lowercaseTab)) {
      setExtraQueryParams({query: AssociatesMembership[lowercaseTab]})
    }

    if (lowercaseTab !== tab) {
      resetCurrentPage()
    }
    onTabClick(lowercaseTab)
  }

  const associatesRequesting = useSelector(selectAssociatesRequesting)
  const {pages: associatesPages} = useSelector(selectAssociatesMeta)
  const select = useMemo(makeGetSortedAssociates, [])
  const associates = useSelector((state: AppState) => select(state, sortOrder))
  const transformedAssociates: TableBodyData[] = transformAssociatesForTable(
    associates,
    membershipValues[tab],
  )

  const handleSort = (sortBy: TSortBy) => {
    setSortOrder(sortBy)
  }

  return (
    <AdminLayout pageTitle="Associate Management">
      <Row>
        <ActionBar
          onAddClick={toggleOpenAddModal}
          headerText="Associate Management"
        />
      </Row>
      <StyledNavLinkRow className="border-bottom mb-3">
        <Nav>
          <SubNavLinks
            tabs={[
              'All',
              'Bond Holder',
              'Green',
              'Silver',
              'Gold',
              'Platinum',
              'Platinum Plus',
              'Brand Ambassador',
              'Deleted Accounts',
            ]}
            currentTab={tab}
            toggleCurrentTab={handleTabClick}
          />
        </Nav>
      </StyledNavLinkRow>

      <BaseModal
        isOpen={openAddModal}
        toggle={toggleOpenAddModal}
        title="Create Account"
      >
        {/* <AddBondFormOverview toggleModal={closeAddModal} /> */}
      </BaseModal>
      {associates.length ? (
        <StyledTableRow className="pb-3">
          <Col sm="12" className="border rounded">
            <TableFilters
              handleSort={handleSort}
              loaded={transformedAssociates.length}
              total={associatesPages * 50}
            />
            <div>
              <AssociateMgmtDetailsTable
                headers={[
                  '',
                  'ID',
                  'Name',
                  'Membership',
                  'Location',
                  'Status',
                  'Join Date',
                  'Referrals',
                  'Units',
                  'Amount',
                ]}
                loading={associatesRequesting}
                page={currentAssociatesPage}
                body={transformedAssociates}
                hasMore={currentAssociatesPage < associatesPages}
                loadMore={loadMoreAssociates}
              />
            </div>
          </Col>
        </StyledTableRow>
      ) : (
        <LoadingOrEmptyModel loading={associatesRequesting} />
      )}
    </AdminLayout>
  )
}

export default DashboardAssociateMgmt
