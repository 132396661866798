import {
  DashboardModelMeta,
  DashboardModelQuery,
} from 'components/common/types/dashboard-models.types'
import {
  FETCH_WITHDRAWALS_START,
  FETCH_WITHDRAWALS_SUCCESS,
  FETCH_WITHDRAWALS_FAILURE,
  SET_WITHDRAWALS_META,
} from './withdrawals.constants'
import {
  NormalizedWithdrawals,
  WithdrawalsActionTypes,
} from './withdrawals.types'

export function fetchWithdrawalsStart(
  data: DashboardModelQuery,
): WithdrawalsActionTypes {
  return {
    type: FETCH_WITHDRAWALS_START,
    payload: data,
  }
}

export function fetchWithdrawalsSuccess(
  data: NormalizedWithdrawals,
): WithdrawalsActionTypes {
  return {
    type: FETCH_WITHDRAWALS_SUCCESS,
    payload: data,
  }
}

export function fetchWithdrawalsFailure(): WithdrawalsActionTypes {
  return {
    type: FETCH_WITHDRAWALS_FAILURE,
  }
}

export function setWithdrawalsMeta(
  meta: DashboardModelMeta,
): WithdrawalsActionTypes {
  return {
    type: SET_WITHDRAWALS_META,
    payload: meta,
  }
}
