/* eslint-disable max-lines-per-function */
import React, {
  FC,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import AdminLayout from 'components/layouts/admin.component'
import {Row, Col, Nav, Input, Label, Button, FormGroup} from 'reactstrap'
import ActionBar from 'components/common/action-bar.component'
import {PlaceholderImage} from 'helpers/placeholder-image'
import {IconContext} from 'react-icons'
import {FiEdit} from 'react-icons/fi'
import {theme} from 'AppTheme'
import {RouteComponentProps} from 'react-router'
import {StyledNavLinkRow} from 'components/common/styles/row.styles'
import BaseModal from 'components/common/base-modal.component'
import {
  makeGetCurrentBondData,
  selectBondsRequesting,
  makeGetCurrentBondSeriesInvestorsData,
  selectBondSeriesInvestorsRequesting,
} from '_redux/bonds/bonds.selectors'
import {useSelector, useDispatch} from 'react-redux'
import {AppState} from '_redux/store.types'
import {
  fetchBondStart,
  fetchBondSeriesInvestorsStart,
  setBondSeriesStatusStart,
  editBondDetailsStart,
} from '_redux/bonds/single/bonds-single.actions'
import {Bond, Series} from '_redux/bonds/single/bonds-single.types'
import {TableBodyData} from 'components/common/types/table-body.types'
import LoadingOrEmptyModel from 'components/common/loading-or-empty-model.component'
import SubNavLinks from 'components/common/sub-nav-links.component'
import {StyledDetailsRow} from './styles'
import DashboardBondSeriesTable from './details-table.component'
import EditBondProfileTabs from './edit-forms.component'
import InvestorsTable from './investors-table.component'
import {transformInvestorsForTable} from './utils'

const colorRed = {color: theme.colors.primary}
const editIconValues = {
  ...colorRed,
  size: '1.4em',
  style: {verticalAlign: 'middle', marginBottom: '4px'},
}

interface MatchParams {
  id: string
}

const DashboardBondProfile: FC<RouteComponentProps<MatchParams>> = ({
  match: {
    params: {id},
  },
}) => {
  const [openEditModal, setOpenEditModal] = useState(false)
  const [currentSeries, setCurrentSeries] = useState('')
  const [imageSrc, setImageSrc] = useState('')
  const [seriesToActivate, setSeriesToActivate] = useState('')
  const fileRef = useRef<HTMLInputElement | null>(null)
  const toggleOpenEditModal = () => {
    setOpenEditModal(prev => !prev)
  }

  const toggleCurrentSeriesTab = useCallback(
    (tab: string) => {
      if (currentSeries !== tab) setCurrentSeries(tab)
    },
    [currentSeries],
  )

  const closeEditModal = () => {
    setOpenEditModal(false)
    setCurrentSeries('')
  }

  const getImageMeta = (imageFile: File): void => {
    const reader = new FileReader()
    reader.onloadend = (): void => {
      if (reader.result) {
        setImageSrc(reader.result as string)
      }
    }

    reader.readAsDataURL(imageFile)
  }

  const handleImageChange = () => {
    const file = fileRef.current?.files?.[0]
    if (!file) return
    getImageMeta(file)
  }

  const cancelImageChange = () => {
    setImageSrc('')
  }

  const dispatch = useDispatch()
  const selectBondData = useMemo(makeGetCurrentBondData, [])
  const bond = useSelector((state: AppState) => selectBondData(state, id))
  const loading = useSelector(selectBondsRequesting)
  const selectBondSeriesInvestorsData = useMemo(
    makeGetCurrentBondSeriesInvestorsData,
    [],
  )
  const bondSeriesInvestors = useSelector((state: AppState) =>
    selectBondSeriesInvestorsData(
      state,
      `${bond?.series?.[currentSeries]?.id || ''}`,
    ),
  )
  const investorsLoading = useSelector(selectBondSeriesInvestorsRequesting)

  const handleSeriesActivate = () => {
    dispatch(
      setBondSeriesStatusStart({
        seriesId: bond.series[seriesToActivate].id,
        bondId: bond.series[seriesToActivate].bond_id,
      }),
    )
    setSeriesToActivate('')
  }

  const handleImageUpload = (): void => {
    if (fileRef.current?.files?.[0]) {
      dispatch(
        editBondDetailsStart({
          name: bond.bond_name,
          location: bond.location,
          description: bond.description,
          bond_id: bond.id,
          image: imageSrc,
          status: 1,
        }),
      )
      setImageSrc('')
    }
  }

  useEffect(() => {
    if (!bond) dispatch(fetchBondStart(id))
  }, [bond, dispatch, id])

  useEffect(() => {
    if (currentSeries)
      dispatch(fetchBondSeriesInvestorsStart(bond.series[currentSeries].id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSeries, dispatch])

  const renderSeriesDetail = useCallback(
    (bondProfile: Bond) => {
      const seriesKeys = bondProfile?.series
        ? Object.keys(bondProfile.series).sort()
        : []
      let firstKey = ''
      if (seriesKeys.length) {
        firstKey = seriesKeys[0]
        // current series is not set or bond has updated
        if (!currentSeries || !bondProfile.series[currentSeries])
          setCurrentSeries(firstKey)
      }
      return (
        <SubNavLinks
          tabs={seriesKeys}
          currentTab={currentSeries}
          toggleCurrentTab={toggleCurrentSeriesTab}
        />
      )
    },
    [currentSeries, toggleCurrentSeriesTab],
  )

  const renderSeriesToActivate = useCallback(
    (series: {[key: string]: Series}) => {
      return Object.keys(series)
        .sort()
        .filter(key => series[key].status === 0)
        .map(seriesKey => (
          <option
            value={seriesKey}
            key={seriesKey}
            onClick={() => {
              setSeriesToActivate(seriesKey)
            }}
          >
            {seriesKey}
          </option>
        ))
    },
    [],
  )

  const transformedInvestors: TableBodyData[] = transformInvestorsForTable(
    bondSeriesInvestors,
  )

  return (
    <AdminLayout pageTitle="Bond Profile">
      <Row>
        <ActionBar headerText="Bond Profile" />
      </Row>
      {bond ? (
        <StyledDetailsRow className="py-3 align-items-start">
          <Col sm="2" className="border rounded pt-3 pb-2 px-3">
            <img
              src={imageSrc || bond.image || PlaceholderImage}
              className="img-fluid img-thumbnail border-0 rounded-circle"
              alt="..."
            />
            <IconContext.Provider value={editIconValues}>
              <Label for="bondImage" className="pt-2 float-right clickable">
                <FiEdit />
              </Label>
              <Input
                type="file"
                name="file"
                id="bondImage"
                accept="image/*"
                className="d-none"
                onChange={handleImageChange}
                innerRef={(instance): void => {
                  fileRef.current = instance
                }}
              />
            </IconContext.Provider>
            {imageSrc ? (
              <div className="d-flex justify-content-between align-items-center mt-5">
                <Button onClick={handleImageUpload}>Upload</Button>
                <Button color="warning" onClick={cancelImageChange}>
                  Cancel
                </Button>
              </div>
            ) : null}
          </Col>
          <Col sm="9" className="border rounded">
            <div className="d-flex justify-content-between align-items-center py-3 border-bottom">
              <div className="font-weight-bold">Details</div>
              <IconContext.Provider value={editIconValues}>
                <div
                  role="button"
                  onClick={toggleOpenEditModal}
                  className="small font-weight-bold"
                >
                  <span className="pr-3">Edit</span>
                  <FiEdit />
                </div>
              </IconContext.Provider>
            </div>
            <div
              className="font-weight-bold py-3 border-bottom"
              style={colorRed}
            >
              {bond.bond_name}
            </div>
            <StyledNavLinkRow className="py-2 px-3">
              <Nav>{renderSeriesDetail(bond)}</Nav>
            </StyledNavLinkRow>
            <Row className="px-3">
              <Col className="px-0" sm="12">
                {bond?.series[currentSeries] ? (
                  <DashboardBondSeriesTable
                    series={bond.series[currentSeries]}
                  />
                ) : (
                  <p>
                    No Series{' '}
                    {Object.keys(bond.series).length ? 'selected' : 'added'}
                  </p>
                )}
              </Col>
            </Row>
            {Object.keys(bond.series).length ? (
              <div className="font-weight-bold pt-3 border-top activate">
                <FormGroup>
                  <Label for="seriesActivate" className="pt-0 pl-0 pr-0" sm={2}>
                    Activate Series
                  </Label>
                  <Col className="pl-0 pb-3" sm={3}>
                    <Input
                      type="select"
                      name="seriesActivate"
                      id="seriesActivate"
                      value={seriesToActivate}
                    >
                      <option
                        value=""
                        onClick={() => {
                          setSeriesToActivate('')
                        }}
                      >
                        Select a series
                      </option>
                      {renderSeriesToActivate(bond.series)}
                    </Input>
                  </Col>
                  <Button
                    onClick={handleSeriesActivate}
                    disabled={!seriesToActivate}
                  >
                    Activate
                  </Button>
                </FormGroup>
              </div>
            ) : null}
          </Col>
          <Col sm="2" className="mt-3" />
          <Col className="border rounded mt-3" sm="9">
            <p className="font-weight-bold pt-3 pb-2">Description</p>
            <p className="small">{bond.description}</p>
          </Col>
          <Col sm="2" className="mt-3" />
          <Col className="border rounded mt-3" sm="9">
            <p className="font-weight-bold pt-3 pb-2">Investors</p>
            <InvestorsTable
              headers={[
                'ID',
                'Name',
                'Membership',
                'Status',
                'Referrals',
                'Units',
                'Amount',
              ]}
              loading={investorsLoading}
              body={transformedInvestors}
            />
          </Col>
        </StyledDetailsRow>
      ) : (
        <LoadingOrEmptyModel loading={loading} />
      )}
      <BaseModal
        isOpen={openEditModal}
        toggle={toggleOpenEditModal}
        title="Create Bond"
      >
        <EditBondProfileTabs
          toggleModal={closeEditModal}
          bond={bond}
          currentSeries={currentSeries}
        />
      </BaseModal>
    </AdminLayout>
  )
}

export default DashboardBondProfile
