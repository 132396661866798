export const ADMIN_AUTH_SESSION_ENDPOINT = '/api/ping-admin'
export const ADMIN_LOGIN_ENDPOINT = '/api-aut-admin-login/'
export const ADMIN_PROFILE_ENDPOINT = '/api/admin-profile'

export const MEMBER_DETAIL_ENDPOINT = '/api/admin-user-detail?id='
export const MEMBER_INVESTMENTS_ENDPOINT = '/api/admin-user-investment?id='
export const MEMBER_REFERRED_ENDPOINT = '/api/admin-user-reffered?id='
export const MEMBER_RETURNS_ENDPOINT = '/api/individual-return?id='
export const MEMBER_REWARDS_ENDPOINT = '/api/individual-reward?id='

export const FETCH_BONDS_ENDPOINT = '/api/admin-view-bond'
export const FETCH_BONDS_SUMMARY_ENDPOINT = '/api/admin-overview'
export const FETCH_BOND_ENDPOINT = '/api/single-bond'
export const ADD_BOND_ENDPOINT = '/api/add-bond'
export const EDIT_BOND_ENDPOINT = '/api/edit-bond'
export const ADD_SERIES_ENDPOINT = '/api/add-series'
export const EDIT_SERIES_ENDPOINT = '/api/edit-series'
export const EDIT_SERIES_STATUS_ENDPOINT = '/api/activate-bond'
export const FETCH_SERIES_INVESTORS_ENDPOINT = '/api/admin/view-investors'
export const FETCH_REFERRALS_ENDPOINT = '/api/latest-referral'

export const FETCH_REFERRED_USERS_ENDPOINT = '/api/referred-users'
export const FETCH_MEMBERS_ENDPOINT = '/api/latest-members'
export const FETCH_ASSOCIATES_ENDPOINT = '/api/associate-users'

export const FETCH_ADS_ENDPOINT = '/api/view-ads'
export const FETCH_AD_ENDPOINT = '/api/view-ads-single_web'
export const ADD_AD_ENDPOINT = '/api/add-ads'

export const FETCH_INVESTMENT_CHART_ENDPOINT = '/api/investment-chart'
export const FETCH_LOCATION_CHART_ENDPOINT = '/api/location-chart'
export const FETCH_REFERRAL_CHART_ENDPOINT = '/api/refferal-chart'

export const FETCH_REWARDS_ENDPOINT = '/api/users-reward'
export const FETCH_RETURNS_ENDPOINT = '/api/users-returns'
export const FETCH_WITHDRAWALS_ENDPOINT = '/api/withdrawal-list'
