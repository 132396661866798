import AssociatesReducer from './associates.reducer'
import {
  FetchAssociatesStartAction,
  FetchAssociatesFailureAction,
  FetchAssociatesSuccessAction,
  SetAssociatesMetaAction,
} from './associates-actions.types'

export type Associate = {
  id: number
  Name: string
  Membership: string
  Location: string
  Status: string
  Date_joined: string
  Referrals: number
  Amount: number
  Unit: number
  user_id: number
}

export type AssociatesDictionary = {
  [key: string]: Associate
}

export type AssociatesIdList = string[]

export type NormalizedAssociates = {
  entities: {
    associates: AssociatesDictionary
  }
  result: AssociatesIdList
}

export enum AssociatesMembership {
  'bond holder',
  green,
  silver,
  gold,
  platinum,
  'platinum plus',
  'brand ambassador',
}

export type AssociatesRequestingStateActions =
  | FetchAssociatesStartAction
  | FetchAssociatesSuccessAction
  | FetchAssociatesFailureAction

export type AssociatesState = ReturnType<typeof AssociatesReducer>

export type AssociatesActionTypes =
  | AssociatesRequestingStateActions
  | FetchAssociatesSuccessAction
  | SetAssociatesMetaAction
