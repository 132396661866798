import {createStore, combineReducers, applyMiddleware, Middleware} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import {createLogger} from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './root-saga'
import userReducer from './user/user.reducer'
import membersReducer from './members/members.reducer'
import bondsReducer from './bonds/bonds.reducer'
import referralsReducer from './referrals/referrals.reducer'
import associatesReducer from './associates/associates.reducer'
import adsReducer from './ads/ads.reducer'
import rewardsReducer from './rewards/rewards.reducer'
import returnsReducer from './returns/returns.reducer'
import withdrawalsReducer from './withdrawals/withdrawals.reducer'

const sagaMiddleware = createSagaMiddleware()
const loggerMiddleware = createLogger()

export const appReducer = combineReducers({
  user: userReducer,
  bonds: bondsReducer,
  referrals: referralsReducer,
  members: membersReducer,
  associates: associatesReducer,
  ads: adsReducer,
  rewards: rewardsReducer,
  returns: returnsReducer,
  withdrawals: withdrawalsReducer,
})

let middleware: Middleware[] = []
if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, loggerMiddleware]
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function configureStore() {
  const middlewareEnhancer = applyMiddleware(sagaMiddleware, ...middleware)

  const store = createStore(appReducer, composeWithDevTools(middlewareEnhancer))

  sagaMiddleware.run(rootSaga)

  return store
}

const store = configureStore()

export default store
