import {AxiosResponse} from 'axios'
import {DashboardModelQuery} from 'components/common/types/dashboard-models.types'
import {getCookieValue} from 'helpers/utils'
import requestHandler from '../axios-config'
import {HttpMethods} from '../axios.types'
import {FETCH_WITHDRAWALS_ENDPOINT} from '../endpoints'

class WithdrawalsService {
  requestHandler: typeof requestHandler
  token: string
  constructor() {
    this.requestHandler = requestHandler
    this.token = getCookieValue('soarRisingToken') || ''
  }

  async fetchWithdrawals(
    query: DashboardModelQuery,
  ): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${FETCH_WITHDRAWALS_ENDPOINT}?page=${query.page}&q=${query.query}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }
}

export default WithdrawalsService
