import {all, call} from 'redux-saga/effects'
import {SagaIterator} from 'redux-saga'
import {userSagas} from './user/user.watchers'
import {membersSagas} from './members/members.watchers'
import {bondsSagas} from './bonds/bonds.watchers'
import {referralsSagas} from './referrals/referrals.watchers'
import {associatesSagas} from './associates/associates.watchers'
import {adsSagas} from './ads/ads.watchers'
import {rewardsSagas} from './rewards/rewards.watchers'
import {returnsSagas} from './returns/returns.watchers'
import {withdrawalsSagas} from './withdrawals/withdrawal.watchers'

export default function* rootSaga(): SagaIterator<void> {
  yield all([
    call(userSagas),
    call(bondsSagas),
    call(referralsSagas),
    call(membersSagas),
    call(associatesSagas),
    call(adsSagas),
    call(rewardsSagas),
    call(returnsSagas),
    call(withdrawalsSagas),
  ])
}
