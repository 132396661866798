import {AxiosResponse} from 'axios'
import {getCookieValue} from 'helpers/utils'
import {DashboardModelQuery} from 'components/common/types/dashboard-models.types'
import requestHandler from '../axios-config'
import {HttpMethods} from '../axios.types'
import {
  FETCH_REFERRALS_ENDPOINT,
  FETCH_REFERRED_USERS_ENDPOINT,
  FETCH_REFERRAL_CHART_ENDPOINT,
} from '../endpoints'

class ReferralsService {
  requestHandler: typeof requestHandler
  token: string
  constructor() {
    this.requestHandler = requestHandler
    this.token = getCookieValue('soarRisingToken') || ''
  }

  async fetchReferrals(page: number): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${FETCH_REFERRALS_ENDPOINT}?page=${page}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }

  async fetchReferredUsers(
    query: DashboardModelQuery,
  ): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${FETCH_REFERRED_USERS_ENDPOINT}?page=${query.page}${
        query.query === undefined ? '' : `&q=${query.query}`
      }`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }

  async getReferralData(): Promise<AxiosResponse> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${FETCH_REFERRAL_CHART_ENDPOINT}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }
}

export default ReferralsService
